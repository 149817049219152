import { createUseStyles } from "react-jss";
import { Theme } from "@/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  chart: {
    "& canvas": {
      height: 320,
      width: "100% !important",
    },
  },
  headerValue: {
    color: theme.colors.colorText,
  },
  gradient: {
    background: theme.colors.colorCardGradient,
    height: 530,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "1rem", // Adjust as per your design
  },
  chartContainer: {
    display: "flex",
    alignItems: "stretch", // Ensures all children are aligned and stretched to the tallest one
    gap: "1rem", // Adds spacing between the cards
  },
}));

export { useStyles };
