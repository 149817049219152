export const initVulnCVE = {
  cveid: "",
  id: "",
  type: "",
  label: "",
  hasSalience: false,
  description: "",
  cvssV4: {
    integrityImpact: "",
    version: "",
    availabilityImpact: "",
    scope: "",
    attackVector: "",
    userInteraction: "",
    baseScore: 0,
    attackComplexity: "",
    confidentialityImpact: "",
    privilegesRequired: "",
    vectorString: "",
  },
  cvssV3: {
    integrityImpact: "",
    version: "",
    availabilityImpact: "",
    scope: "",
    attackVector: "",
    userInteraction: "",
    baseScore: 0,
    attackComplexity: "",
    confidentialityImpact: "",
    privilegesRequired: "",
    vectorString: "",
  },
  cweIds: [],
  smallGraphUrl: "",
  trending: false,
  graphUrl: "",
  epssScore: "",
  patchDetails: [],
  patched: false,
  feedlyInsertedDate: "",
  publishedDate: "",
  publicationDateInfo: [],
  idMapping: [],
};
