import type { Theme } from "../types";

const colors: Theme["colors"] = {
  colorPrimaryBg: "#1C1C1C",
  colorPrimaryBgHover: "#112545",
  colorPrimaryBorder: "#15325b",
  colorPrimaryBorderHover: "#15417e",
  colorPrimaryHover: "#3c89e8",
  colorPrimary: "#1668dc",
  colorPrimaryActive: "#1554ad",
  colorPrimaryTextHover: "#3c89e8",
  colorPrimaryText: "#1668dc",
  colorPrimaryTextActive: "#1554ad",
  colorSecondaryBg: "#000000",
  colorSuccessBg: "#162312",
  colorSuccessBgHover: "#1d3712",
  colorSuccessBorder: "#274916",
  colorSuccessBorderHover: "#306317",
  colorSuccessHover: "#306317",
  colorSuccess: "#49aa19",
  colorSuccessActive: "#3c8618",
  colorSuccessTextHover: "#6abe39",
  colorSuccessText: "#49aa19",
  colorSuccessTextActive: "#3c8618",
  colorWarningBg: "#2b2111",
  colorWarningBgHover: "#443111",
  colorWarningBorder: "#594214",
  colorWarningBorderHover: "#7c5914",
  colorWarningHover: "#7c5914",
  colorWarning: "#d89614",
  colorWarningActive: "#aa7714",
  colorWarningTextHover: "#e8b339",
  colorWarningText: "#d89614",
  colorWarningTextActive: "#aa7714",
  colorErrorBg: "#2c1618",
  colorErrorBgHover: "#451d1f",
  colorErrorBorder: "#5b2526",
  colorErrorBorderHover: "#7e2e2f",
  colorErrorHover: "#e86e6b",
  colorError: "#dc4446",
  colorErrorActive: "#ad393a",
  colorErrorTextHover: "#e86e6b",
  colorErrorText: "#dc4446",
  colorErrorTextActive: "#ad393a",
  colorInfoBg: "#111a2c",
  colorInfoBgHover: "#112545",
  colorInfoBorder: "#15325b",
  colorInfoBorderHover: "#15417e",
  colorInfoHover: "#15417e",
  colorInfo: "#1668dc",
  colorInfoActive: "#1554ad",
  colorInfoTextHover: "#3c89e8",
  colorInfoText: "#1668dc",
  colorInfoTextActive: "#1554ad",
  colorTextQuaternary: "rgba(255, 255, 255, 0.25)",
  colorBorderSecondary: "#303030",
  colorFill: "rgba(255, 255, 255, 0.18)",
  colorFillSecondary: "rgba(255, 255, 255, 0.12)",
  colorFillQuaternary: "rgba(255, 255, 255, 0.04)",
  colorBgLayout: "#1C1C1C",
  colorBgMask: "rgba(0, 0, 0, 0.45)",
  colorBrandBlack100: "#131A14",
  colorBrandBlue100: "#0062A5",
  colorBrandBlue200: "#498DCE",
  colorBrandBlue300: "#69B1FF",
  colorBrandBlue400: "#83C8EE",
  colorBrandRed100: "#f44336",
  colorBrandRed200: "#ff5722",
  colorBrandRed300: "#FB4242",
  colorBrandYellow100: "#ffee55",
  colorBrandYellow200: "#ff9800",
  colorBrandGray100: "#404e5e",
  colorBrandOrange100: "#FF7B23",
  // black color
  colorMainBg: "#1C1C1C",
  colorSiderBG: "#000000",
  colorSiderRightBG: "#141414",
  colorDrawerBG: "#141414",
  colorFormBG: "#282937",
  colorCardBG: "#282937",
  colorFormSelectColor: "#8E8E8E",
  colorFormSearchColor: "#6B6B6B",
  colorFieldOddBG: "#272727",
  colorFieldEvenBG: "#151515",
  colorModalBg: "#141414",
  colorFormBorder: "transparent",
  colorFontTitle: "#FFFFFF",
  colorTextGray: "#6B6B6B",
  colorText: "rgba(255, 255, 255)",
  colorBorder: "transparent",
  colorBgSpotlight: "#3F4157",
  colorCardGradient:
    "linear-gradient(80deg, rgba(35, 35, 49, 1) 15%, rgba(60, 41, 14, 1) 100%)",
  colorBgElevated: "#1C1C1C",
  colorBgContainer: "#1C1C1C",
  colorButtonText: "#FFFFFF",
  colorBUttonImmutate: "#FFFFFF",
  colorTextPlaceholder: "#6B6B6B",
  colorBgHover: "#43455c",
  colorTextSecondary: "rgba(255, 255, 255, 0.65)",
  colorTextTertiary: "rgba(255, 255, 255, 0.45)",
  colorTextTertiaryImmutate: "rgba(255, 255, 255, 0.45)",
  colorTextQuaternaryImmutate: "rgba(255, 255, 255, 0.25)",
  colorBrandWhite: "#ffffff",
  colorBrandBlack: "#000000",
  colorCircleBg: "#6B6B6B",
  colorButtonHover: "#69B1FF",
  colorTagText: "#DFDFDF",
  colorTableBG200: "#001528",
  colorTableBGHover200: "#0a1e32",
  colorTableBGBorder200: "#303030",
  // other color
  colorBgLightBlue: "#498DCE",
  colorBgOrange: "#FF7B23",
  colorBgLightOrange: "#C38A39",
  colorTextGray200: "#8E8E8E",
  colorBlack600: "#6B6B6B",
  colorBlack900: "#000000",
  colorFillTertiary: "rgba(255, 255, 255, 0.08)",
};

export type ColorName = keyof Theme["colors"];

export { colors };
