import { useState, useEffect } from "react";
import clsx from "clsx";
// import ant design
import {
  Row,
  Col,
  Select,
  Card,
  Image,
  Space,
  Form,
  Divider,
  Modal,
  Pagination as CardPagination,
  PaginationProps,
  message,
  Spin,
} from "antd";
import {
  SearchOutlined,
  ExpandAltOutlined,
  ShrinkOutlined,
} from "@ant-design/icons";
// components
import { Button, Input, Typography } from "@/components";
import SliderHorizontal from "@/components/molecules/sliders/SliderHorizontal";
// Import TransitionGroup for animation
import { TransitionGroup, CSSTransition } from "react-transition-group";
// services
import { commonService, feedlyService } from "@/services";
// redux
import { useAppSelector } from "@/redux";
import { selectAccessToken, selectUserInfo } from "@/redux/auth";
// types
import type { Option, Pagination } from "@/types";
import type {
  CreateVendorFormValues,
  CustomerOption,
  VendorMornitor,
} from "./VendorMornitoring.types";
// constants
import { formLayout } from "./VendorMornitoring.constants";
// styles
import { useStyles } from "./VendorMornitoring.styles";
import Dragger from "antd/es/upload/Dragger";
// Icon imports
import FileUploadIcon from "../../../static/images/file_upload.png";

const { Title, Text } = Typography;

const objectsArray: VendorMornitor[] = Array.from(
  { length: 20 },
  (_, index) => ({
    vendor_name: "Amazon Web services Inc.",
    information_provided:
      "Identity data, Business Data, Yosemite,  PII, Active Directory Services",
    pii: "Y",
    ephi: "N",
    client_agent: "N",
    notes: "Jennifer Britland",
    news_stream_id:
      "feed%2Fhttps%3A%2F%2Ffeedly.com%2Ff%2Falert%2F9355538b-e9c7-4301-bb1e-d7da7ff3ed7a",
  })
);

const VendorMonitoring = () => {
  const pageSize = 6; // Number of cards per page
  const [pagination, setPagination] = useState<
    Pagination & { tempVendorName?: string }
  >({
    offset: 0,
    limit: pageSize,
  });
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([]);
  const [vendorNames, setVendorNames] = useState<Option[]>([]);
  const [dataSource, setDataSource] = useState<VendorMornitor[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataSourceTemp, setDataSourceTemp] = useState<VendorMornitor[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [openCreateVendorModal, setOpenCreateVendorModal] =
    useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [creatingVendor, setCreatingVendor] = useState<boolean>(false);

  const accessToken = useAppSelector(selectAccessToken);

  const styles = useStyles();
  const [form] = Form.useForm();

  const fetchCustomerOptions = async () => {
    try {
      // load customer options
      const customers = await commonService.getCustomers(accessToken);
      const newCustomerOptions = customers.map(
        ({ cust_id, storage_container_name, dbconnstr, customer_name }) => ({
          cust_id,
          storage_container_name,
          label: customer_name,
          value: cust_id,
        })
      );
      setCustomerOptions(newCustomerOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchVendorNameSuggestions = async (value: string) => {
    setIsLoading(true);
    try {
      // load customer options
      const suggestedVendors = await feedlyService.getSearchedEntitiesByName({
        token: accessToken,
        value,
      });
      setVendorNames(
        suggestedVendors.map(({ label }) => ({ value: label, label }))
      );
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const fetchVendors = async () => {
    setIsLoading(true);
    const { data, metadata }: any = await feedlyService.getVendorsData({
      token: accessToken,
      params: pagination,
    });
    setDataSource(data);
    setTotalCount(metadata.total);

    setDataSourceTemp(objectsArray);
    setIsLoading(false);
  };

  useEffect(() => {
    if (accessToken) {
      fetchVendors();
    }
  }, [accessToken, pagination]);

  useEffect(() => {
    if (accessToken) {
      fetchCustomerOptions();
    }
  }, [accessToken]);

  const resetClearForm = () => {
    form.resetFields();
    setFile(null);
    if (dataSource.length !== totalCount) {
      setDataSource(dataSourceTemp);
    }
  };

  const [currentPage, setCurrentPage] = useState(1); // Current page state

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (page && pageSize) {
      setPagination({
        offset: (page - 1) * pageSize,
        limit: pageSize,
      });
    }
  };

  const onCloseCreateVendorModal = () => {
    setOpenCreateVendorModal(false);
    form.resetFields();
    setFile(null);
  };

  const onResetCreateVendorForm = () => {
    setOpenCreateVendorModal(false);
    form.resetFields();
    setFile(null);
  };
  const onSubmitCreateVendorForm = async (values: CreateVendorFormValues) => {
    setCreatingVendor(true);

    try {
      // Prepare FormData
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      if (!(values as any)?.cust_id) {
        formData.append("cust_id", userInfo?.cust_id);
      }

      if (file) formData.append("file", file);

      await feedlyService.createVendor({
        payload: formData,
        token: accessToken,
      });
      await fetchVendors();

      // reset form & close modal
      form.resetFields();
      setFile(null);
      setOpenCreateVendorModal(false);

      // notify message
      message.success("A new vendor was created successfully.");
    } catch (error) {
      console.log(error);
      message.error(`${error}`);
    } finally {
      setCreatingVendor(false);
    }
  };

  const handleSearch = ({ vendor_name }) => {
    if (vendor_name) {
      const tempVendorName = String(vendor_name)
        ?.replace(/\s+/g, "")
        ?.toLowerCase();
      setPagination((prevState) => ({
        ...prevState,
        tempVendorName,
      }));
    }
  };

  // Change the pagenation previous and next button
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const rowIcon = (idx: number) => {
    return expandedRows[idx] ? <ShrinkOutlined /> : <ExpandAltOutlined />;
  };

  const requiredSpan = !file ? <span style={{ color: "red" }}> *</span> : "";

  const userInfo = useAppSelector(selectUserInfo);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Text className={clsx([styles.title], "font-poppines")}>
          Vendor Management
        </Text>
        <Button
          className={styles.createMessageBtn}
          disabled={false}
          onClick={() => {
            setOpenCreateVendorModal(true);
          }}
        >
          <Text className={clsx([styles.createBtn], "font-poppines")}>
            + Add a Vendor
          </Text>
        </Button>
      </div>
      <div className={styles.searchContainer}>
        <Title className={styles.panelTitle}>Search Panel</Title>
        <Form form={form} onFinish={handleSearch}>
          <Row className={styles.searchOptionContainer} gutter={32}>
            <Col className={styles.fieldContainer} flex={4}>
              <Form.Item name="vendor_name" className="w-full mb-0">
                <Title className={styles.fieldTitle}>Search</Title>
                <Input
                  size="large"
                  placeholder="Enter vendor name"
                  className={styles.searchBar}
                  prefix={<SearchOutlined />}
                  onChange={(e) => {
                    form.setFieldValue("vendor_name", e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col className={styles.fieldContainer} flex={1}>
              <Button
                htmlType="submit"
                className={styles.searchBtn}
                disabled={false}
              >
                <Text className={styles.createBtn}>Search</Text>
              </Button>
            </Col>
            <Col className={styles.fieldContainer} flex={1}>
              <Button
                onClick={resetClearForm}
                className={styles.clearBtn}
                disabled={false}
              >
                <Text className={styles.createBtn}>Clear</Text>
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className={styles.OptionContainer} gutter={32}>
          <Col flex={5}>
            <Title className={styles.optionTitle}>
              List of Vendors ({totalCount})
            </Title>
          </Col>
        </Row>
      </div>

      {/* Show the reports data with table */}

      <TransitionGroup>
        <CSSTransition key="cards" classNames="fade" timeout={500}>
          <div style={{ marginTop: 15 }}>
            <Row justify="start" gutter={[24, 24]}>
              {dataSource.map((item, index) => (
                <Col xs={24} key={index}>
                  <Card
                    className={styles.cardContainer}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "#282828" : "transparent",
                    }}
                  >
                    <Row justify="space-between" align="middle">
                      <Col>
                        <Text className={styles.value}>{item.vendor_name}</Text>
                      </Col>
                      <Col>
                        <Button
                          type="link"
                          onClick={() => toggleRow(index)}
                          style={{ color: "#1890ff" }}
                        >
                          {rowIcon(index)}
                        </Button>
                      </Col>
                    </Row>
                    {expandedRows[index] && (
                      <>
                        <Divider
                          style={{
                            borderColor: index % 2 === 0 ? "#000" : "#282828",
                          }}
                        />
                        <Row gutter={[16, 16]} style={{ rowGap: 10 }}>
                          <Col xs={24}>
                            <Space
                              direction="vertical"
                              className={clsx([styles.card_text_space])}
                            >
                              <Text className={styles.subtitle}>Newsfeed</Text>
                              <SliderHorizontal
                                streamID={item.news_stream_id}
                                token={accessToken}
                              />
                            </Space>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Card>
                </Col>
              ))}
            </Row>
            <Spin size="large" spinning={isLoading} style={{ left: "50%" }} />
            <div className={styles.cardPagination}>
              <CardPagination
                current={currentPage}
                pageSize={pageSize}
                showSizeChanger={false}
                total={totalCount}
                onChange={handlePageChange}
                itemRender={itemRender}
                responsive
              />
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
      {/* send messgae modal */}
      <Modal
        className={clsx([styles.createMessageModal, "hide-scrollbar"])}
        open={openCreateVendorModal}
        onCancel={onCloseCreateVendorModal}
        footer={null}
        title={
          <Text type="secondary" className="font-regular">
            NEW VENDOR
          </Text>
        }
      >
        <Form
          {...formLayout}
          className={styles.createMessageForm}
          form={form}
          name="create-message"
          requiredMark={false}
          onFinish={onSubmitCreateVendorForm}
        >
          <Title className={clsx([styles.formTitle], "font-regular")}>
            Add a Vendor
          </Title>
          <Row gutter={30}>
            <Col sm={24}>
              <Form.Item
                name="vendor_name"
                label={
                  <span className={styles.formLabel}>
                    Vendor Name{requiredSpan}
                  </span>
                }
                rules={[
                  { required: !file, message: "Vendor Name is required!" },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select"
                  options={vendorNames}
                  onSearch={(value) => fetchVendorNameSuggestions(value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col sm={24}>
              <Form.Item
                name="information_provided"
                label={
                  <span className={styles.formLabel}>
                    Information{requiredSpan}
                  </span>
                }
                rules={[
                  { required: !file, message: "Information is required!" },
                ]}
              >
                <Input placeholder="Type in" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col sm={24}>
              <Form.Item
                name="cust_id"
                label={<span>Customer</span>}
                rules={[{ required: false, message: "Customer is required!" }]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select"
                  options={customerOptions}
                  defaultValue={{
                    label: customerOptions.find(
                      ({ cust_id }) => cust_id == userInfo?.cust_id
                    )?.label,
                    value: userInfo?.cust_id,
                  }}
                  disabled={userInfo?.role != "admin"}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col sm={24}>
              <Form.Item
                name="news_stream_id"
                label={
                  <span className={styles.formLabel}>
                    NewsStream ID{requiredSpan}
                  </span>
                }
                rules={[
                  { required: !file, message: "NewsStream ID is required!" },
                ]}
              >
                <Input placeholder="Type in" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col sm={24}>
              <Form.Item
                name="notes"
                label={<span className={styles.formLabel}>Notes</span>}
                rules={[
                  {
                    required: false,
                    message: "Notes is required!",
                  },
                ]}
              >
                <Input
                  variant="textarea"
                  style={{ minHeight: 80, backgroundColor: "#282937" }}
                  placeholder="Type In"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col sm={24} className="flex justify-center">
              <div>
                <div className={styles.circleOR}>OR</div>
              </div>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col sm={24}>
              <Form.Item
                name="url"
                label={<span className={styles.formLabel}>Upload CSV</span>}
                rules={[
                  { required: false, message: "Vendor File is required!" },
                ]}
              >
                <Dragger
                  className={styles.reportFileDragger}
                  multiple={false}
                  name="files"
                  maxCount={1}
                  accept=".csv"
                  beforeUpload={(file) => {
                    setFile(file); // Store the file in state
                    return false; // Prevent automatic upload
                  }}
                  onChange={(info) => {
                    if (info.file.status === "done") {
                      message.success(
                        `${info.file.name} uploaded successfully.`
                      );
                    } else if (info.file.status === "error") {
                      message.error(`${info.file.name} upload failed.`);
                    }
                  }}
                >
                  <p
                    className="ant-upload-text"
                    style={{
                      color: "#498dce",
                      marginBottom: 15,
                      fontSize: "10px !important",
                    }}
                  >
                    Drag and drop your file here or click to browse
                  </p>
                  <p className="ant-upload-drag-icon">
                    <Image preview={false} src={FileUploadIcon} />
                  </p>
                  <p
                    className="ant-upload-hint"
                    style={{
                      color: "#8E8E8E",
                      marginBottom: 15,
                      fontFamily: "Poppins-Regular",
                      fontSize: "10px !important",
                    }}
                  >
                    Support file type: .CSV
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.formActions}>
            <Button
              htmlType="button"
              className={styles.clearBtn}
              onClick={onResetCreateVendorForm}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={creatingVendor}>
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export { VendorMonitoring };
