import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useStyles } from "./SliderHorizontal.styles";
import { feedlyService } from "@/services";
import moment from "moment";
import { Spin } from "antd";

interface Item {
  id: string;
  title: string;
  summary: { content: string };
  origin: { title: string };
  visual: { url: string };
  published: number;
  continuation?: string;
}

function extractDetailsFromHTML(
  htmlString: string
): (string | React.ReactElement)[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const result: (string | React.ReactElement)[] = [];

  // Recursive function to process child nodes
  function processNode(node: ChildNode): void {
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent?.trim();
      if (text) {
        result.push(text);
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;
      if (
        element.tagName.toLowerCase() === "a" &&
        element.getAttribute("href")
      ) {
        const href = element.getAttribute("href");
        const linkText = element.textContent?.trim() || href;
        if (href) {
          result.push(
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              {linkText}
            </a>
          );
        }
      } else {
        element.childNodes.forEach(processNode);
      }
    }
  }

  // Start processing the child nodes of the body
  doc.body.childNodes.forEach(processNode);

  return result;
}

const SliderHorizontal = ({ streamID, token }) => {
  const classes = useStyles();
  const sliderRef = useRef<HTMLDivElement>(null); // Reference to the slider container

  const [items, setItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [continuation, setContinuation] = useState<string>("");

  const fetchNews = async (continuation = "") => {
    setLoading(true);

    try {
      const response = await feedlyService.getArticlesByStreamIdV2({
        token,
        stream_id: streamID,
        continuation,
      });

      setItems((prevItems) => [...prevItems, ...response.items]);
      setContinuation(response.continuation);
    } catch (error) {
      console.error("Error fetching news:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);
  useEffect(() => {
    console.log("i run");
    setItems([]);
    fetchNews();
  }, [streamID]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const bottom =
      target.scrollHeight === target.scrollTop + target.clientHeight;
    if (bottom && continuation && !loading) {
      fetchNews(continuation);
    }
  };

  const handleArrowClick = (direction: "left" | "right") => {
    if (sliderRef.current) {
      const scrollAmount = direction === "left" ? -300 : 300; // Adjust the scroll amount as needed
      sliderRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div className={classes.sliderContainer} onScroll={handleScroll}>
      {/* Left Arrow */}
      <button
        className={`${classes.sliderArrow} ${classes.leftArrow}`}
        onClick={() => handleArrowClick("left")}
      >
        &lt;
      </button>

      <div ref={sliderRef} className={classes.sliderContent}>
        {items.map((item) => (
          <div key={item?.id} className={classes?.sliderCard}>
            <div className={classes?.cardImageContainer}>
              <img
                src={item?.visual?.url}
                alt={item?.title}
                className={classes?.cardImage}
              />
            </div>
            <div className={classes?.cardContent}>
              <h3 className={classes?.cardTitle}>{item?.title}</h3>
              <div className={classes?.cardSource}>
                <span className={classes?.cardAuthor}>
                  {item?.origin?.title}
                </span>
                <span className={classes?.cardTime}>
                  | {moment(item?.published).format("DD-MM-YYYY")}
                </span>
              </div>
              <p className={classes?.cardDescription}>
                {extractDetailsFromHTML(item?.summary?.content)}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Right Arrow */}
      <button
        className={`${classes.sliderArrow} ${classes.rightArrow}`}
        onClick={() => handleArrowClick("right")}
      >
        &gt;
      </button>
      <Spin
        size="default"
        spinning={loading}
        className={classes.loadingIndicator}
      />
    </div>
  );
};

export default SliderHorizontal;
