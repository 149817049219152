export const DEBOUNCE_WAIT = 2000;

export const DEFAULT_SORTER = "upload_date_time";

export const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
export const VENDOR_LIST = [
  {
    label: "Amazon Web Services, Inc",
    value:
      "feed%2Fhttps%3A%2F%2Ffeedly.com%2Ff%2Falert%2F9355538b-e9c7-4301-bb1e-d7da7ff3ed7a",
  },
  {
    label: "Apple Inc",
    value:
      "feed%2Fhttps%3A%2F%2Ffeedly.com%2Ff%2Falert%2Fbd8c1af7-eb31-404c-bb85-a0f453307df2",
  },
  {
    label: "AT&T Corp",
    value:
      "feed%2Fhttps%3A%2F%2Ffeedly.com%2Ff%2Falert%2F8f366ecd-2a65-4526-804b-8d8dc46a27eb",
  },
  {
    label: "AT&T Mobility II LLC",
    value:
      "feed%2Fhttps%3A%2F%2Ffeedly.com%2Ff%2Falert%2F0f7dd6b2-f6bb-4b83-b354-8f14c877a0a6",
  },
  {
    label: "Atlassian US LLC",
    value:
      "feed%2Fhttps%3A%2F%2Ffeedly.com%2Ff%2Falert%2Febbefb19-c8b8-48d4-bd8d-659acac5c848",
  },
];
