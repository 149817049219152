export const STREAM_IDS = {
  CORE_TECH: "ed3a386d-02d0-4933-ab57-41a58245382f", // CORE TECH SPECILITY
  CVES: "48d6c23c-d71f-473a-bc3d-65bd2a468ff5",
  CVSS: "34ce5776-e3e1-41a5-a484-d7766d0b71be",
  CYBER: "8b751c51-34b7-4e10-9ddc-7fdc08e26746", // cyber security
  INSURANCE: "c883b46f-69fe-43cf-a605-300e150f6ae0",
  THREAT_RESEARCH: "7001d1d8-7a7a-4578-976f-91ec47bfc6a3",
  SECURITY_ADVISORIES: "c6bc6f39-bfb5-469d-b56d-ca0e96a7ac79", // CYBER SECURITY
};

export const FEEDLY_NEWS_TYPE = [
  {
    id: "ed3a386d-02d0-4933-ab57-41a58245382f",
    label: "Core Tech Stack",
    value: "coreSpeciality",
  },
  {
    id: "c883b46f-69fe-43cf-a605-300e150f6ae0",
    label: "Insurance",
    value: "insuranceArticles",
  },
  {
    id: "category/8b751c51-34b7-4e10-9ddc-7fdc08e26746",
    label: "Cyber Security News",
    value: "cyberSecurity",
  },
  {
    id: "c6bc6f39-bfb5-469d-b56d-ca0e96a7ac79",
    label: "Security Advisories",
    value: "securityAdvisories",
  },
];
