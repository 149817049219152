import { useState, useEffect } from "react";

// components
import { Typography, Dropdown, Spin, Alert } from "antd"; // Use Ant Design Alert
import { ArticleCardView } from "./ArticleCardView";
import { ArticleTableView } from "./ArticleTableView";
import { Select } from "antd";

// theme
import { Icons } from "@/theme/icons";

// service
import { feedlyService } from "@/services";

// redux
import { useAppSelector } from "@/redux";
import { selectAccessToken, selectUserInfo } from "@/redux/auth";

// constants
import { FEEDLY_NEWS_TYPE, STREAM_IDS } from "./ArticlesTab.constants";

// types
import { FeedlyArticle } from "@/types";
import { ArticlesTabProps, NEWS_VIEW_MODE } from "./ArticlesTab.types";

// styles
import { useStyles } from "./ArticlesTab.styles";

const { Title } = Typography;

const ArticlesTab = ({ onViewArticle }: ArticlesTabProps) => {
  const userInfo = useAppSelector(selectUserInfo);
  const org_name = userInfo?.org_name;
  const accessToken = useAppSelector(selectAccessToken);
  const styles = useStyles();

  // Explicit type definitions
  const [articles, setArticles] = useState<{
    coreSpeciality: FeedlyArticle[];
    insuranceArticles: FeedlyArticle[];
    cyberSecurity: FeedlyArticle[];
    securityAdvisories: FeedlyArticle[];
  }>({
    coreSpeciality: [],
    insuranceArticles: [],
    cyberSecurity: [],
    securityAdvisories: [],
  });

  const [selectedNewsType, setSelectedNewsType] = useState(
    org_name === "Core Specialty" ? "coreSpeciality" : "cyberSecurity"
  );
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [viewMode, setViewMode] = useState<NEWS_VIEW_MODE>(
    NEWS_VIEW_MODE.TABLE
  );

  const [error, setError] = useState<string | null>(null);

  const fetchArticles = async () => {
    setLoadingArticles(true);
    setError(null);
    try {
      const [core, insurance, cyberSecurity, securityAdvisories] =
        await Promise.all([
          feedlyService.getArticlesByStreamID({
            token: accessToken,
            stream_id: STREAM_IDS.CVSS, // Replace with actual STREAM_IDS value
          }),
          feedlyService.getArticlesByStreamID({
            token: accessToken,
            stream_id: STREAM_IDS.INSURANCE,
          }),
          feedlyService.getArticlesByStreamID({
            token: accessToken,
            stream_id: STREAM_IDS.CYBER,
          }),
          feedlyService.getArticlesByStreamID({
            token: accessToken,
            stream_id: STREAM_IDS.SECURITY_ADVISORIES,
          }),
        ]);

      setArticles({
        coreSpeciality: core as FeedlyArticle[],
        insuranceArticles: insurance as FeedlyArticle[],
        cyberSecurity: cyberSecurity as FeedlyArticle[],
        securityAdvisories: securityAdvisories as FeedlyArticle[],
      });
    } catch (err: any) {
      console.error("Error fetching articles:", err);
      setError(
        err?.response?.data?.msg ||
          "An unexpected error occurred while fetching articles."
      );
    } finally {
      setLoadingArticles(false);
    }
  };

  useEffect(() => {
    if (accessToken) fetchArticles();
  }, [accessToken]);

  const handleNewsTypeChange = (value: string) => {
    setSelectedNewsType(value);
  };

  const renderNewsItem = (data: FeedlyArticle[]) => {
    switch (viewMode) {
      case NEWS_VIEW_MODE.CARD:
        return (
          <ArticleCardView newsData={data} onViewArticle={onViewArticle} />
        );
      case NEWS_VIEW_MODE.TABLE:
      default:
        return (
          <ArticleTableView newsData={data} onViewArticle={onViewArticle} />
        );
    }
  };

  return (
    <Spin size="small" spinning={loadingArticles}>
      <div className={styles.root}>
        {error && (
          <Alert
            type="error"
            message="Error"
            description={error}
            showIcon
            closable
            onClose={() => setError(null)}
          />
        )}
        <div className={styles.viewModeDropdown}>
          <Dropdown
            trigger={["click"]}
            menu={{
              selectable: true,
              items: [
                { key: NEWS_VIEW_MODE.CARD, label: "Cards View" },
                { key: NEWS_VIEW_MODE.TABLE, label: "Table View" },
              ],
              selectedKeys: [viewMode],
              onSelect: ({ selectedKeys }) => {
                setViewMode(selectedKeys[0] as NEWS_VIEW_MODE); // Explicit cast
              },
            }}
          >
            <Icons glyph="layout-outlined" />
          </Dropdown>
        </div>
        <div className={styles.articles}>
          <div className={styles.section}>
            <Title className={styles.title}>Select News Type</Title>
            <Select
              options={FEEDLY_NEWS_TYPE.map((type) => ({
                label: type.label,
                value: type.value,
              }))}
              value={selectedNewsType}
              popupClassName={styles.popupSelect}
              placeholder="Select"
              onChange={handleNewsTypeChange}
              showSearch
              allowClear
            />
            {renderNewsItem(articles[selectedNewsType] || [])}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export { ArticlesTab };
