export const DEBOUNCE_WAIT = 2000;

export const DEFAULT_SORTER = "upload_date_time";

export const date_options = [
  {
    key: "0",
    value: "Till Today",
  },
  {
    key: "1",
    value: "1 Week",
  },
  {
    key: "2",
    value: "1 Month",
  },
  {
    key: "3",
    value: "1 Year",
  },
];

export const sort_report_options = [
  {
    key: "date",
    value: "Date",
  },
  {
    key: "customer_name",
    value: "Customer Name",
  },
];
