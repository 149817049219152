import { createUseStyles } from "react-jss";
import { Theme } from "@/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  sliderContainer: {
    display: "flex",
    position: "relative",
    overflow: "hidden",
    marginTop: "30px",
  },
  sliderContent: {
    display: "flex",
    overflowX: "scroll",
    scrollBehavior: "smooth",
    padding: "10px 40px",
    gap: "1rem",
  },
  sliderCard: {
    display: "flex",
    backgroundColor: theme.colors.colorCardBG,
    borderRadius: "8px",
    color: theme.colors.colorFontTitle,
    overflow: "hidden",
    width: "400px",
    minWidth: "400px",
    height: "120px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
  },
  cardImageContainer: {
    flexShrink: 0,
    width: "140px",
    height: "100%",
  },
  cardImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    padding: "10px",
    borderRadius: "24px",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "scroll",
    padding: "8px 12px",
    alignItems: "baseline",
    width: "67%",
  },
  cardTitle: {
    fontSize: "14px",
    fontWeight: "600",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: 0,
  },
  cardMeta: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "12px",
    color: "#FF8C00",
    fontWeight: "500",
  },
  cardBadge: {
    backgroundColor: "#FF8C00",
    color: "#FFFFFF",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: "600",
  },
  cardSource: {
    fontSize: "12px",
    color: "#AAAAAA",
    fontWeight: "400",
  },
  cardAuthor: {
    color: theme.colors.colorFontTitle,
    fontWeight: "500",
  },
  cardTime: {
    marginLeft: "4px",
  },
  cardDescription: {
    fontSize: "12px",
    color: "#CCCCCC",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginTop: "4px",
    display: "flex",
    flexDirection: "column",
  },
  sliderArrow: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    background: theme.colors.colorSiderBG,
    color: theme.colors.colorFontTitle,
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    width: "32px",
    height: "32px",
    fontWeight: "bolder",
    fontSize: "larger",
    zIndex: 1,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
  },
  loadingIndicator: {
    textAlign: "center",
    padding: "20px",
    color: "#888",
    fontSize: "16px",
  },
  leftArrow: { left: "0" },
  rightArrow: { right: "0" },
}));

export { useStyles };
